import { mapActions, mapGetters } from "vuex";
import * as types from "../../../store/types";
import authService from "../../../services/authService";

export default {
  name: "merchant-navigations",
  components: {},
  props: [],
  data() {
    return {
      merchantIdValue: localStorage.getItem("bitrahAccessToken")
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO
    }),
    checkEnv() {
      return process.env.VUE_APP_MODE === "development" ||
        process.env.VUE_APP_MODE === "staging"
        ? true : process.env.VUE_APP_MODE === "production" ?
        false : '';
    },
    defineEnv() {
      return process.env.VUE_APP_MODE
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      checkIsAuth: types.ACTION_CHECK_IS_AUTH
    }),
    logout() {
      this.loading = true;
      authService
        .logout()
        .then(() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("bitrahAccessToken");
          localStorage.removeItem("bitrahAdminAccessToken");
          localStorage.setItem("isAuth", false);
          this.checkIsAuth();
          /* this.$store.commit("resetAll"); */
          this.$router.push(`/${this.$i18n.locale}`);
        })
        .catch(() => {
          this.$bvToast.toast(this.$i18n.t("nav.error"), {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
